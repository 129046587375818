import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import menuItems from '../json/menu';
// import dogIllustration from '../images/dog-illustration.svg';

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Профф`,
          `Пыть-Ях`,
          `дополнительное образование`,
          `об организации`,
        ]}
        title='Главная'
      />

      <section className='flex flex-col items-center '>
        {menuItems.map(({ title, link }) => (
          <Link
            to={link}
            key={title}
            className='p-4 bg-white w-full color-gray-600 hover:shadow-lg border hover:border-gray-400 my-1 rounded
            transform hover:scale-105 transition 
              ease-in-out duration-700'
          >
            {title}
          </Link>
        ))}
      </section>
    </Layout>
  );
}

export default React.memo(AboutPage);
