export default [
  { title: 'Основные сведения', link: '/MainInformation' },
  {
    title: 'Структура и органы управления образовательной организации',
    link: '/Structure',
  },
  { title: 'Документы', link: '/Documents' },
  { title: 'Образование', link: '/Education' },
  { title: 'Платные образовательные услуги', link: '/PaidServices' },
  { title: 'Образовательные стандарты', link: '/EduStandarts' },
  {
    title: 'Руководство. Педагогический (научно-педагогический) состав',
    link: '/Teachers',
  },
  {
    title:
      'Материально-техническое обеспечение и оснащенность образовательного процесса',
    link: '/Materials',
  },
  {
    title: 'Стипендии и иные виды материальной поддержки',
    link: '/Scolarships',
  },
  {
    title: 'Архив',
    link: '/Archive',
  },
  {
    title: 'Финансово-хозяйственная деятельность',
    link: '/Finances',
  },
  {
    title: 'Вакантные места для приема (перевода)',
    link: '/Vacancies',
  },
  {
    title: 'Доступная среда',
    link: '/Area',
  },
  {
    title: 'Международное сотрудничество',
    link: '/International',
  },
];
